import moment from 'moment';
import DatePicker from 'react-datepicker';
import { CSVLink } from 'react-csv';
import PerfectScrollbar from 'perfect-scrollbar';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Header, OrderModel, Sidebar } from '../../Components';
import { API } from '../../api';
import { toast } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from '../../Components/Loader';
import Select, { StylesConfig } from 'react-select';
import Switch from 'react-switch';
import { useDispatch, useSelector } from 'react-redux';
import getRestaurantData from '../../store/actions/getRestaurantData';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import { Label } from '@material-ui/icons';

let ps: any;

function Orders() {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [OrderData, setOrderData] = useState<any[]>([]);

  const [orderId, setOrderId] = useState(0);
  const [Articleobj, setArticleobj] = useState(0);
  const [startDate, SetStartDate] = useState<any>(new Date());
  const [endDate, SetendDate] = useState<any>(new Date());
  const [selectedFormat, setSelectedFormat] = useState('csv');
  const [articleData, setArticleData] = useState<any>([]);
  const headers = [
    { label: 'OrderID', key: 'OrderID' },
    { label: 'Statusbestelling', key: 'Statusbestelling' },
    { label: 'Betalingstatus', key: 'Betalingstatus' },
    { label: 'Tafelnummer', key: 'Tafelnummer' },
    { label: 'OpmerkingKlant', key: 'Opmerkingklant' },
    { label: 'NaamKlant', key: 'NaamKlant' },
    { label: 'E_mail', key: 'E_mail' },
    { label: 'GSM', key: 'GSM' },
    { label: 'Adres', key: 'Adres' },
    { label: 'BTW_percentage', key: 'BTW_percentage' },
    { label: 'Bedrag', key: 'Bedrag' },
    { label: 'Voorwanneer', key: 'Voorwanneer' },
  ];

  const [data, setCsvData] = useState([]);
  const [is_loading, setIsLoading] = useState<boolean>(false);
  const orderStatusSelectOpt = [
    { value: 'pending', label: t('Orders.orders_filter_status_pending') },
    { value: 'accepted', label: t('Orders.orders_filter_status_accepted') },
    { value: 'rejected', label: t('Orders.orders_filter_status_rejected') },
  ];
  const [filterStatus, setFilterStatus] = useState<{ value: string; label: string } | null>(
    orderStatusSelectOpt[0]
  );

  const dispatch = useDispatch();
  const restaurant = useSelector(({ restaurantData }: StoreRoot) => restaurantData);
  const [isAutoAcceptOrders, setAutoAcceptOrders] = useState<boolean>(false);

  const mainPanel = useRef<any>();
  const id = localStorage.getItem('rest_id') as string;

  useEffect(() => {
    document.title = 'Orders';
    // @ts-ignore
    document.getElementById('orders_colorchange').style.color = 'darkred';
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [modal]);

  function formatDate(inputDate:any) {
    if(!inputDate) return ''
    const date = new Date(inputDate);
    
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
    const year = date.getFullYear().toString();
    
    return `${day}.${month}.${year}`;
}

  const getAllOrdersListFunc = async (id: string) => {
    const sdate = moment(startDate.getTime()).format('YYYY-MM-DD');
    const edate = moment(endDate.getTime()).format('YYYY-MM-DD');

    setIsLoading(true);

    await API.restaurantApi
      .getAllOrdersList(id, sdate, edate)
      .then((res: any) => {
        setIsLoading(false);

        let finalRes: any = [];
        let orderGroupRes: any = {};
        let suborderGroupRe: any = {};

        res.map((orders: any) => {
          const orderType = orders.order_type === 'Pickup' ? 'pickuptax' : 'dineintax';
          const orderDate = moment(orders.ordertime.split('+')[0]).format('DD, MMMM YYYY');

          function getLatestDate(dateString1: Date, dateString2: Date) {
            const date1 = new Date(dateString1);
            const date2 = new Date(dateString2);

            return date1 > date2 ? date1 : date2;
          }

          for (let elem of orders.article) {
            if (elem.article.articlename in orderGroupRes) {
              orderGroupRes[elem.article.articlename].orderCount += elem.article_quantity;
              orderGroupRes[elem.article.articlename].latestDate = getLatestDate(
                orders.ordertime,
                orderGroupRes[elem.article.articlename].latestDate
              );
            } else {
              orderGroupRes[elem.article.articlename] = {
                orderCount: elem.article_quantity,
                latestDate: new Date(orders.ordertime),
              };
            }
          }

          for (let elem of orders?.article) {
            for (let option of elem?.article_options) {
              const optionChoice = option?.article_option?.choice;

              if (optionChoice in suborderGroupRe) {
                suborderGroupRe[optionChoice].orderCount += 1;
                suborderGroupRe[optionChoice].latestDate = getLatestDate(
                  orders?.ordertime,
                  suborderGroupRe[optionChoice].latestDate
                );
              } else {
                suborderGroupRe[optionChoice] = {
                  orderCount: 1,
                  latestDate: new Date(orders?.ordertime),
                };
              }
            }
          }

          const subTotal = {
            OrderID: `${orders.order_number}`,
            Statusbestelling: String(orders.order_status),
            Betalingstatus: String(orders.payment_status),
            Tafelnummer: orders.tableid === -1 ? '' : `${orders.tableid}`,
            OpmerkingKlant: orders.user_note,
            NaamKlant: orders.customer_name,
            E_mail: orders.email,
            GSM: orders.phoneNumber,
            Adres: orders.address,
            BTW_percentage: '',
            Bedrag: orders.total_amount,
            Voorwanneer:
              orders.delivery_time && orders.tableid === -1
                ? moment(orders.delivery_time.split('+')[0]).format('DD, MMMM YYYY HH:mm')
                : '',
            OrderDate:
              orders.ordertime && orders.tableid === -1
                ? moment(orders.ordertime.split('+')[0]).format('DD, MMMM YYYY')
                : '',
            Product: orders.article.map((article: any) => article.article.articlename).join(', '),
          };
          finalRes.push(subTotal);

          const singleItemList = productArray(subTotal, orders.article, orderType);

          Object.keys(singleItemList).map((item) => {
            const articleWithVat = {
              OrderID: `${orders.order_number}`,
              Statusbestelling: String(orders.order_status),
              Betalingstatus: String(orders.payment_status),
              Tafelnummer: orders.tableid === -1 ? '' : `${orders.tableid}`,
              OpmerkingKlant: orders.user_note,
              NaamKlant: orders.customer_name,
              E_mail: orders.email,
              GSM: orders.phoneNumber,
              Adres: orders.address,
              BTW_percentage: item,
              Bedrag: singleItemList[item],
              Voorwanneer:
                orders.delivery_time && orders.tableid === -1
                  ? moment(orders.delivery_time.split('+')[0]).format('DD, MMMM YYYY HH:mm')
                  : '',
              OrderDate:
                orders.ordertime && orders.tableid === -1
                  ? moment(orders.ordertime.split('+')[0]).format('DD, MMMM YYYY')
                  : '',
              Product: orders.article.map((article: any) => article.article.articlename).join(', '),
            };
            return finalRes.push(articleWithVat);
          });
        });

        // generate Download Article file
        const wsData = [['OrderDate', 'Product', 'Amount']];

        for (let elem in orderGroupRes) {
          const csvRow = [];
          csvRow.push(orderGroupRes[elem].latestDate);
          csvRow.push(elem);
          csvRow.push(orderGroupRes[elem].orderCount);
          wsData.push(csvRow);
        }
        for (const key in suborderGroupRe) {
          const csvRow = [];
          csvRow.push(suborderGroupRe[key].latestDate);
          csvRow.push(key);
          csvRow.push(suborderGroupRe[key].orderCount);
          wsData.push(csvRow);
        }
        setArticleData(wsData);
        setCsvData(finalRes);
        setOrderData(res);
      })
      .catch((err) => console.log('Error is', err.message));
  };

  useEffect(() => {
    const rest_id = localStorage.getItem('rest_id') as string;

    getAllOrdersListFunc(rest_id);
    dispatch(getRestaurantData(rest_id));
  }, [id]);

  useEffect(() => {
    // @ts-ignore
    setAutoAcceptOrders(restaurant.auto_accept_orders);
  }, [restaurant]);

  const getLatestDate = (dateString1: Date, dateString2: Date) => {
    const date1 = new Date(dateString1);
    const date2 = new Date(dateString2);

    return date1 > date2 ? date1 : date2;
  };

  const getFilterdata = async () => {
    const rest_id = localStorage.getItem('rest_id') as string;
    const sdate = moment(startDate.getTime()).format('YYYY-MM-DD');
    const edate = moment(endDate.getTime()).format('YYYY-MM-DD');

    await API.restaurantApi
      .getAllOrdersList(rest_id, sdate, edate)
      .then((res: any) => {
        let finalRes: any = [];
        let orderGroupRes: any = {};
        let suborderGroupRe: any = {};

        res.map((orders: any) => {
          const orderType = orders.order_type === 'Pickup' ? 'pickuptax' : 'dineintax';
          const orderDate = moment(orders.ordertime.split('+')[0]).format('DD, MMMM YYYY');

          for (let elem of orders?.article) {
            if (elem.article.articlename in orderGroupRes) {
              orderGroupRes[elem.article.articlename].orderCount += elem.article_quantity;
              orderGroupRes[elem.article.articlename].latestDate = getLatestDate(
                orders.ordertime,
                orderGroupRes[elem.article.articlename].latestDate
              );
            } else {
              orderGroupRes[elem.article.articlename] = {
                orderCount: elem.article_quantity,
                latestDate: new Date(orders?.ordertime),
              };
            }
          }

          for (let elem of orders?.article) {
            for (let option of elem?.article_options) {
              const optionChoice = option?.article_option?.choice;

              if (optionChoice in suborderGroupRe) {
                suborderGroupRe[optionChoice].orderCount += 1;
                suborderGroupRe[optionChoice].latestDate = getLatestDate(
                  orders?.ordertime,
                  suborderGroupRe[optionChoice].latestDate
                );
              } else {
                suborderGroupRe[optionChoice] = {
                  orderCount: 1,
                  latestDate: new Date(orders?.ordertime),
                };
              }
            }
          }

          const subTotal = {
            OrderID: `${orders.order_number}`,
            Statusbestelling: String(orders.order_status),
            Betalingstatus: String(orders.payment_status),
            Tafelnummer: orders.tableid === -1 ? '' : `${orders.tableid}`,
            OpmerkingKlant: orders.user_note,
            NaamKlant: orders.customer_name,
            E_mail: orders.email,
            GSM: orders.phoneNumber,
            Adres: orders.address,
            BTW_percentage: '',
            Bedrag: orders.total_amount,
            Voorwanneer:
              orders.delivery_time && orders.tableid === -1
                ? moment(orders.delivery_time.split('+')[0]).format('DD, MMMM YYYY HH:mm')
                : '',
            OrderDate:
              orders.ordertime && orders.tableid === -1
                ? moment(orders.ordertime.split('+')[0]).format('DD, MMMM YYYY')
                : '',
            Product: orders.article.map((article: any) => article.article.articlename).join(', '),
          };
          finalRes.push(subTotal);

          const singleItemList = productArray(subTotal, orders.article, orderType);

          Object.keys(singleItemList).map((item) => {
            const articleWithVat = {
              OrderID: `${orders.order_number}`,
              Statusbestelling: String(orders.order_status),
              Betalingstatus: String(orders.payment_status),
              Tafelnummer: orders.tableid === -1 ? '' : `${orders.tableid}`,
              OpmerkingKlant: orders.user_note,
              NaamKlant: orders.customer_name,
              E_mail: orders.email,
              GSM: orders.phoneNumber,
              Adres: orders.address,
              BTW_percentage: item,
              Bedrag: singleItemList[item],
              Voorwanneer:
                orders.delivery_time && orders.tableid === -1
                  ? moment(orders.delivery_time.split('+')[0]).format('DD, MMMM YYYY HH:mm')
                  : '',
              OrderDate:
                orders.ordertime && orders.tableid === -1
                  ? moment(orders.ordertime.split('+')[0]).format('DD, MMMM YYYY')
                  : '',
              Product: orders.article.map((article: any) => article.article.articlename).join(', '),
            };
            return finalRes.push(articleWithVat);
          });
        });

        // generate Download Article file
        const wsData = [['OrderDate', 'Product', 'Amount']];

        for (let elem in orderGroupRes) {
          const csvRow = [];
          csvRow.push(orderGroupRes[elem].latestDate);
          csvRow.push(elem);
          csvRow.push(orderGroupRes[elem].orderCount);
          wsData.push(csvRow);
        }
        for (const key in suborderGroupRe) {
          const csvRow = [];
          csvRow.push(suborderGroupRe[key].latestDate);
          csvRow.push(key);
          csvRow.push(suborderGroupRe[key].orderCount);
          wsData.push(csvRow);
        }
        setArticleData(wsData);
        setCsvData(finalRes);
        setOrderData(res);
      })
      .catch((err) => console.log('Error is', err.message));
  };

  const toggle = (id: any) => {
    setModal(!modal);
    setOrderId(id);
  };
  const closeModal = (e: any) => {
    e.preventDefault();
    setModal(!modal);
  };

  const onChangeStatus = async (e: React.ChangeEvent<HTMLSelectElement>, order_number: any) => {
    const new_status = e.target.value;
    const formData = new FormData();
    formData.append('order_status', new_status);

    await API.restaurantApi
      .updateOrderStatus(String(order_number), formData)
      .then(() => {
        toast.success(t('Add_Edit_hours.editOpeningHrs_successfully_updated_msg'), {
          containerId: 'B',
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(() => {
        window.location.reload();
      });
  };

  const renderStatus = (currentStatus: string, order_number: any) => {
    if (currentStatus === 'Pending') {
      return (
        <select name="pets" id="pet-select" onChange={(e) => onChangeStatus(e, order_number)}>
          <option value="Pending" defaultChecked>
            {currentStatus}
          </option>
          <option value="Accepted">Accepted</option>
          <option value="Rejected">Rejected</option>
        </select>
      );
    } else {
      return currentStatus;
    }
  };

  // Autorefresh Orders List each 7 seconds
  useEffect(() => {
    const refres_interval = setInterval(() => {
      const rest_id = localStorage.getItem('rest_id') as string;
      getAllOrdersListFunc(rest_id);
    }, 7000);
    return () => {
      clearInterval(refres_interval);
    };
  }, [getFilterdata]);

  const genCsvFinalizeData = () => {
    let orderGroupRes: any = {};
    let suborderGroupRe: any = {};

    for (const orders of OrderData) {
      // Skip orders that don't match the filter status
      if (orders?.order_status?.toLowerCase() !== filterStatus?.value.toLowerCase()) {
        continue;
      }

      for (let elem of orders?.article) {
        if (elem.article.articlename in orderGroupRes) {
          orderGroupRes[elem.article.articlename].orderCount += elem.article_quantity;
          orderGroupRes[elem.article.articlename].latestDate = getLatestDate(
            orders.delivery_time,
            orderGroupRes[elem.article.articlename].latestDate
          );
        } else {
          orderGroupRes[elem.article.articlename] = {
            orderCount: elem.article_quantity,
            latestDate: new Date(orders?.delivery_time),
          };
        }
      }

      for (let elem of orders?.article) {
        for (let option of elem?.article_options) {
          const optionChoice = option?.article_option?.choice;

          if (optionChoice in suborderGroupRe) {
            suborderGroupRe[optionChoice].orderCount += 1;
            suborderGroupRe[optionChoice].latestDate = getLatestDate(
              orders?.delivery_time,
              suborderGroupRe[optionChoice].latestDate
            );
          } else {
            suborderGroupRe[optionChoice] = {
              orderCount: 1,
              latestDate: new Date(orders?.delivery_time),
            };
          }
        }
      }
    }

    const wsData = [['OrderDate', 'Product', 'Amount']];
    const startingData = formatDate(startDate)
    const endingData = formatDate(endDate)

    for (let elem in orderGroupRes) {
      const csvRow = [];
      csvRow.push(`${startingData} - ${endingData}`);
      csvRow.push(elem);
      csvRow.push(orderGroupRes[elem].orderCount);
      wsData.push(csvRow);
    }
    for (const key in suborderGroupRe) {
      const csvRow = [];
      csvRow.push(`${startingData} - ${endingData}`);
      csvRow.push(key);
      csvRow.push(suborderGroupRe[key].orderCount);
      wsData.push(csvRow);
    }
    return wsData;
  };

  const exportToXlsx = (event: any) => {
    // Create worksheet data and export it to XLSX
    const wsData = [
      headers.map((header) => header.label),
      ...data.map((row: any) => headers.map((header) => row[header.key])),
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Orders');
    const exportFileName = `orderdata_${new Date().toISOString()}.xlsx`;
    XLSX.writeFile(wb, exportFileName);
    // Update the selected format state
    setSelectedFormat(event.target.value);
  };

  const articleExportToXlsx = (event: any) => {
    const data = genCsvFinalizeData();
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Orders');
    const exportFileName = `Articledata_${new Date().toISOString()}.xlsx`;
    XLSX.writeFile(wb, exportFileName);
    setSelectedFormat(event.target.value);
  };

  const handleFilterStatus = (val: { value: string; label: string } | null) => {
    setFilterStatus(val);
  };

  // Styles For React Select
  const selectStyles = {
    control: (base: any) => ({
      ...base,
      height: '27px',
      minHeight: '27px',
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: '27px',
      padding: '0 6px',
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: (state: any) => ({
      display: 'none',
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: '27px',
    }),
    option: (base: any) => ({
      ...base,
      height: '27px',
      textAlign: 'start',
      paddingTop: '5px',
    }),
  };

  const handlePrintNow = (order_id: any) => {
    API.restaurantApi
      .printOrderNow(order_id)
      .then(() => {
        toast.success('Order was sent to printer!', { containerId: 'B' });
        const rest_id = localStorage.getItem('rest_id') as string;
        getAllOrdersListFunc(rest_id);
      })
      .catch(() => {
        toast.error('Order was not sent to printer!', { containerId: 'B' });
      });
  };

  const renderPrintButton = (order_id: any, print_now: any) => {
    return (
      <button
        onClick={(e: any) => handlePrintNow(order_id)}
        disabled={print_now}
        className="btn btn-danger btn-sm"
      >
        Print
      </button>
    );
  };
  const handleAutoAcceptOrders = async (val: boolean) => {
    // @ts-ignore
    const res = await API.restaurantApi.changeAutoAcceptOrders(restaurant.id, val);
  };

  const toggleAutoAcceptOrders = () => {
    handleAutoAcceptOrders(!isAutoAcceptOrders);
    setAutoAcceptOrders(!isAutoAcceptOrders);
  };

  function productArray(order: any, productArr: any, orderType: any) {
    const vatListWithPrice: any = {};

    productArr.map((item: any) => {
      if (vatListWithPrice.hasOwnProperty(item.article[orderType])) {
        vatListWithPrice[item.article[orderType]] += Number(item.article_total_prize);
      } else {
        vatListWithPrice[item.article[orderType]] = Number(item.article_total_prize);
      }
      return vatListWithPrice;
    });

    return vatListWithPrice;
  }

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />
        <div className="row mt-5 pt-5">
          <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
            <div className="table-responsive">
              <span className="h4 mb-3">
                {/* Overzicht bestellingen */}
                {t('Orders.orders_title')}
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div style={{ display: 'flex' }}>
                <form
                  style={{ display: 'flex' }}
                  onSubmit={(e) => {
                    e.preventDefault();
                    getFilterdata();
                  }}
                >
                  <div>
                    <label
                      htmlFor="sel1"
                      className="control-label font-weight-bold m-0 mb-2 mt-3"
                      style={{ padding: '2px 10px 0px' }}
                    >
                      {/* Start Date */}
                      {t('Orders.orders_filter_startDate')}
                    </label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => SetStartDate(date)}
                      dateFormat="dd-MM-yyyy"
                      required
                      className="date-picker"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="sel1"
                      className="control-label font-weight-bold m-0 mb-2 mt-3"
                      style={{ padding: '2px 10px 0px' }}
                    >
                      {/* End Date */}
                      {t('Orders.orders_filter_endDate')}
                    </label>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => SetendDate(date)}
                      dateFormat="dd-MM-yyyy"
                      required
                      className="date-picker"
                    />
                  </div>

                  <div style={{ minWidth: '10rem', marginTop: '1rem', marginLeft: '1.5rem' }}>
                    <Select
                      styles={selectStyles}
                      onChange={handleFilterStatus}
                      options={orderStatusSelectOpt}
                      defaultValue={filterStatus}
                    />
                  </div>

                  <div style={{ padding: '0px 20px 8px' }}>
                    <button
                      type="submit"
                      className="btn btn-success  shadow-none"
                      style={{
                        padding: '3px 33px 3px',
                        marginTop: '12px',
                        borderRadius: '7px',
                      }}
                    >
                      {/* Filter */}
                      {t('Orders.orders_button_filter')}
                    </button>
                  </div>
                </form>

                <div
                  style={{ marginTop: '4px', display: 'flex', alignItems: 'center', gap: '10px' }}
                >
                  <CSVLink
                    data={data}
                    headers={headers}
                    target="_blank"
                    className="btn btn-primary btn-success btn-sm"
                    style={{ borderRadius: '7px' }}
                    filename={`orderdata_${new Date().toISOString()}.csv`}
                  >
                    {t('Orders.orders_button_downloadCsv')}
                  </CSVLink>

                  <button
                    onClick={exportToXlsx}
                    className="btn btn-primary btn-success btn-sm"
                    style={{ borderRadius: '7px' }}
                  >
                    {t('Orders.orders_button_downloadXlsx')}
                  </button>

                  <button
                    onClick={articleExportToXlsx}
                    className="btn btn-primary btn-success btn-sm"
                    style={{ borderRadius: '7px' }}
                  >
                    {t('Orders.orders_button_Downloadartikellijst')}
                    {/* Download artikellijst */}
                  </button>
                </div>
              </div>
              {/* <label className="d-flex align-items-center p-2" htmlFor="auto_accept">
                <span className="mr-2">Auto accept orders</span>
                <Switch
                  id="auto_accept"
                  onChange={(e) => toggleAutoAcceptOrders()}
                  onColor="#dc3545"
                  name="auto_accept_orders"
                  checked={isAutoAcceptOrders || false}
                />
              </label> */}
              {is_loading && <Loader />}
              <table className="table table-striped table-bordered mt-3">
                <thead>
                  <tr>
                    {/* <th className="small"></th> */}
                    <th className="small">
                      {/* Order ID */}
                      {t('Orders.order_tabel_header_orderid')}
                    </th>
                    <th className="small">
                      {/* Status bestelling */}
                      {t('Orders.order_tabel_header_orderStatus')}
                    </th>
                    <th className="small">
                      {/* Betalingstatus */}
                      {t('Orders.order_tabel_header_paymentStatus')}
                    </th>
                    <th className="small">
                      {/* Tafelnummer */}
                      {t('Orders.order_tabel_header_panelNumber')}
                    </th>
                    <th className="small">
                      {/* Opmerking klant */}
                      {t('Orders.order_tabel_header_customerNote')}
                    </th>
                    <th className="small">
                      {/* Naam klant */}
                      {t('Orders.order_tabel_header_customerName')}
                    </th>
                    <th className="small">
                      {/* E-mail */}
                      {t('Orders.order_tabel_header_Email')}
                    </th>
                    <th className="small">
                      {/* GSM */}
                      {t('Orders.order_tabel_header_phoneNumber')}
                    </th>
                    <th className="small">
                      {/* Adres */}
                      {t('Orders.order_tabel_header_address')}
                    </th>

                    <th className="small">
                      {/* Bedrag */}
                      {t('Orders.order_tabel_header_amount')}
                    </th>
                    <th className="small">
                      {/* Voorwanneer  */}
                      {t('Orders.order_tabel_header_forWhen')}
                    </th>
                    <th className="small">
                      {/* Bestelling */}
                      {t('Orders.order_tabel_header_order')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {OrderData?.length > 0 &&
                    OrderData.map((order: any, index) => {
                      if (order?.order_status?.toLowerCase() !== filterStatus?.value.toLowerCase())
                        return null;
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            {/* <td className="small">
                              {renderPrintButton(order.order_number, order.print_now)}
                            </td> */}
                            <td className="small">{order.order_number}</td>
                            <td className="small">
                              {renderStatus(order.order_status, order.order_number)}
                            </td>
                            <td className="small">{String(order.payment_status)}</td>
                            <td className="small">{order.tableid === -1 ? '' : order.tableid}</td>
                            <td className="small">{order.user_note ? order.user_note : ''}</td>
                            <td className="small">
                              {order.customer_name ? order.customer_name : ''}
                            </td>
                            <td className="small">{order.email ? order.email : ''}</td>
                            <td className="small">{order.phoneNumber ? order.phoneNumber : ''}</td>
                            <td className="small">{order.address ? order.address : ''}</td>
                            <td className="small">{order.total_amount}</td>
                            <td className="small">
                              {order.delivery_time && order.tableid === -1
                                ? `${moment(order.delivery_time.split('+')[0]).format(
                                    'DD, MMMM YYYY HH:mm'
                                  )}`
                                : ''}
                            </td>
                            <td className="small">
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                                onClick={() => toggle(order.order_number)}
                              />
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  <tr style={{ height: '2rem' }}></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <OrderModel modal={modal} setModal={setModal} toggle={closeModal} orderId={orderId} />
    </>
  );
}

export default Orders;
